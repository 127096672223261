ul.list-tools{
  list-style-type: none;
  padding: 0;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}

.btn-animated{
  background-color: transparent;
  border : 1px solid blue;
  transition: 300ms;
  border-radius: .25rem;
  width: 2.3rem;
  height: 2.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-animated:hover{
  transition: 300ms;
  width: 10rem;
}

.black{
  color: black;
  background-color: #2d404f;
}