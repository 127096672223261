.modal-header{
  background: #F8F9FD;
}

.modal-title{
  font-size: 14px;
  color: #4C5A6F;
}

.btn-close{
  font-size: 10px;
}
