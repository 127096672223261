.background{
    background-color: #008fb5;
    height: 100vh;
    display: flex;
    justify-content: center;
}
.form{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:340px;
    height:440px;
    background:white;
    border-radius:10px;
    box-shadow:0 0 40px -10px #000;
    padding:20px 30px;max-width:calc(100vw - 40px);
    box-sizing:border-box;
    font-family:'Montserrat',sans-serif;
    margin:auto;
}
.form > h2{
    margin:5px 0;
    padding-bottom:5px;
    width:220px;
    color:#008fb5;
    border-bottom:3px solid #008fb5;
    text-align: center;
}
.form input{
    width:100%;
    padding:2px;
    font-family:'Montserrat',sans-serif;
    transition:all .5s;
    border-bottom: solid 1px #008fb5;
    border-left:none;
    border-top:none;
    border-right:none;
}
    
.form input:hover{
    border-bottom:1px solid #008fb5;
    border-left:none;
    border-top:none;
    border-right:none;
    
}
.form > p:before{
    content:attr(type);
    display:block;
    margin:28px 0 0;
    font-size:14px;
    color:#008fb5
}
.form > button{
    float:right;
    padding:8px 12px;
    margin:8px 0 0;
    font-family:'Montserrat',sans-serif;
    background-color:#008fb5;
    color:#fff;
    cursor:pointer;
    transition:all .5s
}
.form > button:hover{
    background:#08a1cc;
    color:#fff
}
.form > div{
    content:'Hi';
    position:absolute;
    bottom:-15px;
    right:-20px;
    background:#50505a;
    color:#fff;
    width:320px;
    padding:16px 4px 16px 0;
    border-radius:6px;
    font-size:13px;
    box-shadow:10px 10px 40px -14px #000
}
.form > span{
    margin:0 5px 0 15px
}